

<template>
<div>
  <div class="row">
    <div class="col-12">
      <div class="card shadow-sm border">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h3 class="my-0">
              Products
            </h3>
            <router-link to="/products/create" class="btn btn-primary">
              <i class="mdi font-14 mdi-plus"></i> <span class="d-none d-sm-inline"> Add Product</span></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                        <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control"></b-form-input>
                    </label>
                </div>
            </div>
          </div>

          <div class="table-responsive mb-0">
              <b-table table-class="table table-centered w-100" thead-tr-class="bg-light" 
                :items="products" :fields="columns" responsive="sm" :per-page="perPage" :current-page="currentPage"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                  <template v-slot:cell(check)="data">
                      <div class="custom-control custom-checkbox text-center">
                          <input type="checkbox" class="custom-control-input" :id="`contacusercheck${data.item.id}`" />
                          <label class="custom-control-label" :for="`contacusercheck${data.item.id}`">{{data.item.id}}</label>
                      </div>
                  </template>
                  <template v-slot:cell(image)="data">
                      <img @click="navigateTo(data.item.id)" :src="absoluteUrl(data.item.thumb_path)" alt="" class="rounded" style="cursor: pointer;" height="48" />
                  </template>
                  <template v-slot:cell(name)="data">
                      <h5 class="m-0 d-inline-block align-middle">
                          <a href="#" @click.prevent="navigateTo(data.item.id)" class="text-dark">{{ data.item.name }}</a>
                      </h5>
                  </template>
                    <template v-slot:cell(category)="data">
                      <h5 class="m-0 d-inline-block align-middle">
                          <a href="#" @click.prevent="navigateTo(data.item.id)" class="text-dark">{{ data.item.category.name }}</a>
                      </h5>
                  </template>
                  <template v-slot:cell(price)="data">
                      ${{ data.item.price }}
                  </template>
                  <template v-slot:cell(is_active)="data">
                      <span class="badge badge-soft-success" :class="{'badge-soft-danger': !data.item.is_active}">{{ data.item.is_active ? 'Active' : 'Inactive' }}</span>
                  </template>
                  <template v-slot:cell(is_featured)="data">
                    <span class="d-block text-center">
                      <span class="badge badge-soft-success" :class="{'badge-soft-danger': !data.item.is_featured}">{{ data.item.is_featured? '✔️' : '❌' }}</span>
                    </span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <ul class="list-inline table-action m-0">
                      <li class="list-inline-item">
                        <a href="javascript:void(0);" @click.prevent="deleteItem(data.item.id,data.item.name)" class="action-icon text-danger">
                          <i class="mdi mdi-delete"></i></a>
                      </li>
                    </ul>
                  </template>
              </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      columns: [
          {
              key: "check",
              label: "ID"
          },
          {
              key: "image",
              sortable: true
          },
          {
              key: "name",
              sortable: true
          },
          {
              key: "category",
              sortable: true
          },
          {
              key: "price",
              sortable: true
          },
          {
              key: "is_active",
              label: "Status",
              sortable: true
          },
          {
              key: "is_featured",
              label: "Featured",
              sortable: true
          },
          "action"
      ]
    }
  },
  computed: {
    products() {
      return this.$store.state.productList.products
    },
    rows() {
        return this.products.length;
    },
  },
  methods: {
    navigateTo(id){
      this.$router.push({path: `/products/${id}`});
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteItem(itemId, name){
      this.confirmDelete({
        text: `You are about to delete this product '${name}'!`,
      }).then((result) => {
        if(result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/products/${itemId}/delete`)
          .then((response) =>{
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
              this.$store.commit("productList/DELETE_PRODUCT", itemId)
            }
          })
        }
      });
    }
  },
  created() {
    this.$store.dispatch("productList/fetchProducts")
  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style lang="scss">

</style>
